import { SvgIcon, SvgIconProps } from '@mui/material';
import {
  ArrowLeft,
  ArrowRight,
  Buildings,
  Cards,
  CaretDown,
  CloudRain,
  DownloadSimple,
  Eye,
  GearFine,
  HandTap,
  ListBullets,
  Question,
  SignIn,
  SignOut,
  Warning,
  X,
  CloudArrowUp,
  Plus,
} from '@phosphor-icons/react';
import { AiFillSliders, AiOutlineColumnHeight } from 'react-icons/ai';
import { BsClouds, BsFillArrowRightCircleFill } from 'react-icons/bs';
import {
  FaBatteryEmpty,
  FaBatteryQuarter,
  FaChartBar,
  FaFileContract,
  FaPen,
  FaRegStickyNote,
  FaRegTrashAlt,
  FaWater,
  FaRedoAlt,
  FaTrash,
} from 'react-icons/fa';
import {
  GiTap,
  GiTeePipe,
  GiThermometerCold,
  GiWindsock,
} from 'react-icons/gi';
import { HiOutlineStatusOffline } from 'react-icons/hi';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';
import {
  IoIosAdd,
  IoIosCheckmarkCircleOutline,
  IoIosClose,
  IoMdBuild,
} from 'react-icons/io';
import {
  IoAirplaneSharp,
  IoSettingsSharp,
  IoTrendingDown,
  IoTrendingUp,
  IoVolumeMuteOutline,
  IoUnlinkOutline,
} from 'react-icons/io5';
import {
  MdArrowBack,
  MdAutoFixHigh,
  MdAutoGraph,
  MdCompassCalibration,
  MdEditNote,
  MdErrorOutline,
  MdExpandMore,
  MdInfoOutline,
  MdKeyboardArrowDown,
  MdLogout,
  MdMenu,
  MdWarningAmber,
  MdZoomIn,
  MdZoomOut,
} from 'react-icons/md';
import { RxExternalLink } from 'react-icons/rx';
import {
  TbActivity,
  TbArrowBigDown,
  TbArrowBigUp,
  TbBuildingFactory,
  TbManualGearbox,
  TbSearch,
  TbSwitch2,
} from 'react-icons/tb';
import { TfiViewList } from 'react-icons/tfi';
import { FiUsers } from 'react-icons/fi';
import { PiCheckBold, PiXBold, PiPipe, PiPipeDuotone } from 'react-icons/pi';
import Drop from '~/shared/components/Icons/internal/drop.svg?react';
import Info from '~/shared/components/Icons/internal/info.svg?react';
import Reservoir from '~/shared/components/Icons/internal/reservoir.svg?react';
import Sensor from '~/shared/components/Icons/internal/sensor.svg?react';
import Tap from '~/shared/components/Icons/internal/tap.svg?react';
import Pump from '~/shared/components/Icons/internal/pump.svg?react';

export function ActivityIcon(props: SvgIconProps) {
  return <SvgIcon component={TbActivity} inheritViewBox {...props} />;
}

export function AirplaneIcon(props: SvgIconProps) {
  return <SvgIcon component={IoAirplaneSharp} inheritViewBox {...props} />;
}

export function ArrowBackIcon(props: SvgIconProps) {
  return <SvgIcon component={MdArrowBack} inheritViewBox {...props} />;
}

export function ArrowBigDownIcon(props: SvgIconProps) {
  return <SvgIcon component={TbArrowBigDown} inheritViewBox {...props} />;
}

export function ArrowBigTopIcon(props: SvgIconProps) {
  return <SvgIcon component={TbArrowBigUp} inheritViewBox {...props} />;
}

export function ArrowRightCircleFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={BsFillArrowRightCircleFill} inheritViewBox {...props} />
  );
}

export function AutoFixHighIcon(props: SvgIconProps) {
  return <SvgIcon component={MdAutoFixHigh} inheritViewBox {...props} />;
}

export function AutoGraphIcon(props: SvgIconProps) {
  return <SvgIcon component={MdAutoGraph} inheritViewBox {...props} />;
}

export function BatteryEmptyIcon(props: SvgIconProps) {
  return <SvgIcon component={FaBatteryEmpty} inheritViewBox {...props} />;
}

export function BatteryQuarterIcon(props: SvgIconProps) {
  return <SvgIcon component={FaBatteryQuarter} inheritViewBox {...props} />;
}

export function BuildingFactoryIcon(props: SvgIconProps) {
  return <SvgIcon component={TbBuildingFactory} inheritViewBox {...props} />;
}

export function ChartBarIcon(props: SvgIconProps) {
  return <SvgIcon component={FaChartBar} inheritViewBox {...props} />;
}

export function RetryIcon(props: SvgIconProps) {
  return <SvgIcon component={FaRedoAlt} inheritViewBox {...props} />;
}

export function ChevronDownIcon(props: SvgIconProps) {
  return <SvgIcon component={CaretDown} inheritViewBox {...props} />;
}

export function ChevronLeftIcon(props: SvgIconProps) {
  return <SvgIcon component={HiChevronLeft} inheritViewBox {...props} />;
}

export function ChevronRightIcon(props: SvgIconProps) {
  return <SvgIcon component={HiChevronRight} inheritViewBox {...props} />;
}

export function CloudsIcon(props: SvgIconProps) {
  return <SvgIcon component={BsClouds} inheritViewBox {...props} />;
}

export function CloudRainIcon(props: SvgIconProps) {
  return <SvgIcon component={CloudRain} inheritViewBox {...props} />;
}

export function CompassCalibrationIcon(props: SvgIconProps) {
  return <SvgIcon component={MdCompassCalibration} inheritViewBox {...props} />;
}

export function DashboardIcon(props: SvgIconProps) {
  return <SvgIcon component={Cards} inheritViewBox {...props} />;
}

export function DownloadIcon(props: SvgIconProps) {
  return <SvgIcon component={DownloadSimple} inheritViewBox {...props} />;
}

export function DropIcon(props: SvgIconProps) {
  return <SvgIcon component={Drop} inheritViewBox {...props} />;
}

export function ExternalLinkIcon(props: SvgIconProps) {
  return <SvgIcon component={RxExternalLink} inheritViewBox {...props} />;
}

export function UnlinkIcon(props: SvgIconProps) {
  return <SvgIcon component={IoUnlinkOutline} inheritViewBox {...props} />;
}

export function ErrorIcon(props: SvgIconProps) {
  return <SvgIcon component={MdErrorOutline} inheritViewBox {...props} />;
}

export function EyeIcon(props: SvgIconProps) {
  return <SvgIcon component={Eye} inheritViewBox {...props} />;
}

export function FileContractIcon(props: SvgIconProps) {
  return <SvgIcon component={FaFileContract} inheritViewBox {...props} />;
}

export function FillSlidersIcon(props: SvgIconProps) {
  return <SvgIcon component={AiFillSliders} inheritViewBox {...props} />;
}

export function InfoIcon(props: SvgIconProps) {
  return <SvgIcon component={MdInfoOutline} inheritViewBox {...props} />;
}

export function IosAddIcon(props: SvgIconProps) {
  return <SvgIcon component={IoIosAdd} inheritViewBox {...props} />;
}

export function IosCheckmarkCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={IoIosCheckmarkCircleOutline}
      inheritViewBox
      {...props}
    />
  );
}

export function IosCloseIcon(props: SvgIconProps) {
  return <SvgIcon component={IoIosClose} inheritViewBox {...props} />;
}

export function TestingIcon(props: SvgIconProps) {
  return <SvgIcon component={IoMdBuild} inheritViewBox {...props} />;
}

export function InformationCircleIcon(props: SvgIconProps) {
  return <SvgIcon component={Info} inheritViewBox {...props} />;
}

export function KeyboardArrowDownIcon(props: SvgIconProps) {
  return <SvgIcon component={MdKeyboardArrowDown} inheritViewBox {...props} />;
}

export function LogoutIcon(props: SvgIconProps) {
  return <SvgIcon component={MdLogout} inheritViewBox {...props} />;
}

export function ManualGearboxIcon(props: SvgIconProps) {
  return <SvgIcon component={TbManualGearbox} inheritViewBox {...props} />;
}

export function MenuIcon(props: SvgIconProps) {
  return <SvgIcon component={MdMenu} inheritViewBox {...props} />;
}

export function OutlineStatusOfflineIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={HiOutlineStatusOffline} inheritViewBox {...props} />
  );
}

export function PenIcon(props: SvgIconProps) {
  return <SvgIcon component={FaPen} inheritViewBox {...props} />;
}

export function RegStickyNoteIcon(props: SvgIconProps) {
  return <SvgIcon component={FaRegStickyNote} inheritViewBox {...props} />;
}

export function RegTrashAltIcon(props: SvgIconProps) {
  return <SvgIcon component={FaRegTrashAlt} inheritViewBox {...props} />;
}

export function ReservoirIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={Reservoir}
      inheritViewBox
      sx={{ stroke: 'currentColor', fill: 'none' }}
      {...props}
    />
  );
}

export function SearchIcon(props: SvgIconProps) {
  return <SvgIcon component={TbSearch} inheritViewBox {...props} />;
}

export function SensorIcon(props: SvgIconProps) {
  return <SvgIcon component={Sensor} inheritViewBox {...props} />;
}

export function SettingsIcon(props: SvgIconProps) {
  return <SvgIcon component={IoSettingsSharp} inheritViewBox {...props} />;
}

export function SupportIcon(props: SvgIconProps) {
  return <SvgIcon component={Question} inheritViewBox {...props} />;
}

export function Switch2Icon(props: SvgIconProps) {
  return <SvgIcon component={TbSwitch2} inheritViewBox {...props} />;
}

export function SystemIcon(props: SvgIconProps) {
  return <SvgIcon component={Buildings} inheritViewBox {...props} />;
}

export function SystemsListIcon(props: SvgIconProps) {
  return <SvgIcon component={TfiViewList} inheritViewBox {...props} />;
}

export function TapIcon(props: SvgIconProps) {
  return <SvgIcon component={Tap} inheritViewBox {...props} />;
}

export function TapOpenIcon(props: SvgIconProps) {
  return <SvgIcon component={GiTap} inheritViewBox {...props} />;
}

export function ThermometerColdIcon(props: SvgIconProps) {
  return <SvgIcon component={GiThermometerCold} inheritViewBox {...props} />;
}

export function TrendingDownIcon(props: SvgIconProps) {
  return <SvgIcon component={IoTrendingDown} inheritViewBox {...props} />;
}

export function TrendingUpIcon(props: SvgIconProps) {
  return <SvgIcon component={IoTrendingUp} inheritViewBox {...props} />;
}

export function VolumeMuteIcon(props: SvgIconProps) {
  return <SvgIcon component={IoVolumeMuteOutline} inheritViewBox {...props} />;
}

export function WarningAmberIcon(props: SvgIconProps) {
  return <SvgIcon component={MdWarningAmber} inheritViewBox {...props} />;
}

export function WaterIcon(props: SvgIconProps) {
  return <SvgIcon component={FaWater} inheritViewBox {...props} />;
}

export function WindsockIcon(props: SvgIconProps) {
  return <SvgIcon component={GiWindsock} inheritViewBox {...props} />;
}

export function ZoomInIcon(props: SvgIconProps) {
  return <SvgIcon component={MdZoomIn} inheritViewBox {...props} />;
}

export function ZoomOutIcon(props: SvgIconProps) {
  return <SvgIcon component={MdZoomOut} inheritViewBox {...props} />;
}

export function ExpandMoreIcon(props: SvgIconProps) {
  return <SvgIcon component={MdExpandMore} inheritViewBox {...props} />;
}

export function DrainIcon(props: SvgIconProps) {
  return <SvgIcon component={GiTeePipe} inheritViewBox {...props} />;
}

export function IssuesIcon(props: SvgIconProps) {
  return <SvgIcon component={Warning} inheritViewBox {...props} />;
}

export function LevelIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={AiOutlineColumnHeight} inheritViewBox {...props} />
  );
}

export function ListIcon(props: SvgIconProps) {
  return <SvgIcon component={ListBullets} inheritViewBox {...props} />;
}

export function ManualControlIcon(props: SvgIconProps) {
  return <SvgIcon component={HandTap} inheritViewBox {...props} />;
}

export function SignInIcon(props: SvgIconProps) {
  return <SvgIcon component={SignIn} inheritViewBox {...props} />;
}

export function SignOutIcon(props: SvgIconProps) {
  return <SvgIcon component={SignOut} inheritViewBox {...props} />;
}

export function CloseIcon(props: SvgIconProps) {
  return <SvgIcon component={X} inheritViewBox {...props} />;
}

export function ArrowLeftIcon(props: SvgIconProps) {
  return <SvgIcon component={ArrowLeft} inheritViewBox {...props} />;
}

export function ArrowRightIcon(props: SvgIconProps) {
  return <SvgIcon component={ArrowRight} inheritViewBox {...props} />;
}

export function GearIcon(props: SvgIconProps) {
  return <SvgIcon component={GearFine} inheritViewBox {...props} />;
}

export function CloudArrowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={CloudArrowUp} weight="bold" inheritViewBox {...props} />
  );
}

export function PlusIcon(props: SvgIconProps) {
  return <SvgIcon component={Plus} inheritViewBox {...props} />;
}

export function DeleteIcon(props: SvgIconProps) {
  return <SvgIcon component={FaTrash} inheritViewBox {...props} />;
}

export function UsersIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={FiUsers}
      sx={{ stroke: 'currentColor', fill: 'none' }}
      inheritViewBox
      {...props}
    />
  );
}

export function CheckmarkIcon(props: SvgIconProps) {
  return <SvgIcon component={PiCheckBold} inheritViewBox {...props} />;
}

export function XIcon(props: SvgIconProps) {
  return <SvgIcon component={PiXBold} inheritViewBox {...props} />;
}

export function NoteIcon(props: SvgIconProps) {
  return <SvgIcon component={MdEditNote} inheritViewBox {...props} />;
}

export function PumpIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={Pump}
      sx={{ stroke: 'currentColor', strokeWidth: 12 }}
      inheritViewBox
      {...props}
    />
  );
}

export function PipeIcon(props: SvgIconProps) {
  return <SvgIcon component={PiPipe} inheritViewBox {...props} />;
}

export function PipeDuotoneIcon(props: SvgIconProps) {
  return <SvgIcon component={PiPipeDuotone} inheritViewBox {...props} />;
}
