import { useQuery } from '@tanstack/react-query';
import { ListIssuesResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { useLoaderData, useParams } from 'react-router-dom';
import { listOpenIssuesForSystemQuery } from '~/shared/api/issues.queries';
import { useRequiredParam } from '~/shared/hooks/useRequiredParam';
import { nameFromId } from '~/shared/models/id-utils';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import { assertIsDefined } from '~/types/assert-type';

export type IssuesLoaderData = {
  issues?: ListIssuesResponse;
} | null;

export const useOpenIssuesQuery = (): ListIssuesResponse | undefined => {
  const { systemId } = useParams();
  const loaderData = useLoaderData() as Partial<IssuesLoaderData>;

  const hasSystemId = !!systemId;
  const loadedIssues = !!loaderData?.issues;

  // This hook is used for both editing and creating modes in the SystemUpsertPage.
  // In create mode, 'systemId' is not available, so issues are not needed.
  // We only fetch issues if they are not already present in the loader data and 'systemId' exists.
  const enabled = hasSystemId && loadedIssues;

  return useQuery({
    ...listOpenIssuesForSystemQuery({
      systemName: systemId ? nameFromId(systemId) : 'unknown',
    }),
    initialData: loaderData?.issues,
    enabled,
  }).data;
};

export const useOptionalOpenIssuesForSystem = (): IssuesModel | undefined => {
  const systemId = useRequiredParam('systemId');
  const initialData = useLoaderData() as IssuesLoaderData;

  const { data } = useQuery({
    ...listOpenIssuesForSystemQuery({
      systemName: nameFromId(systemId),
    }),
    initialData: initialData?.issues,
  });
  return data && new IssuesModel(data);
};

export const useOpenIssuesForSystem = (): IssuesModel => {
  const openIssuesModel = useOptionalOpenIssuesForSystem();
  assertIsDefined(openIssuesModel);
  return openIssuesModel;
};
