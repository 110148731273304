import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { listOpenIssuesForSystemQuery } from '~/shared/api/issues.queries';
import { waterLevelsMetricsQuery } from '~/shared/api/metrics.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import {
  checkAuthorization,
  checkIfUserHasPermissionForSystem,
} from '~/shared/auth/auth-utils';
import { IssuesLoaderData } from '~/shared/hooks/issues-hooks';
import { SystemLoaderData } from '~/shared/hooks/systems-hooks';
import {
  createSystemDesignRoute,
  createSystemRoute,
} from '~/shared/models/create-routes';
import { nameFromId } from '~/shared/models/id-utils';
import { WaterLevelsModel } from '~/shared/models/metrics/waterLevelsModel/WaterLevelsModel';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export type SystemPageLoaderResult = SystemLoaderData &
  IssuesLoaderData & {
    waterLevels: WaterLevelsModel;
  };

export const systemLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemPageLoaderResult | Response> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      const listSystemsResponse = await listSystemsResponsePromise;
      const redirectUrl = createSystemRoute(
        new ListSystemsModel(listSystemsResponse).systems[0].id,
      );
      return redirect(redirectUrl);
    }

    const [listSystemsResponse, listIssuesResponse] = await Promise.all([
      listSystemsResponsePromise,
      queryClient.ensureQueryData(
        listOpenIssuesForSystemQuery({ systemName: nameFromId(systemId) }),
      ),
    ]);

    const listSystemsModel = new ListSystemsModel(
      listSystemsResponse,
      listIssuesResponse,
    );

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    const userHasDesignPermissions = await checkIfUserHasPermissionForSystem(
      queryClient,
      request,
      systemId,
      [
        SystemPermission_Permission.DESIGN,
        SystemPermission_Permission.COMMISSIONING,
      ],
    );

    const systemInDesignPhase =
      system.state === 'ADVICE' || system.state === 'PRODUCTION_EDIT';

    if (userHasDesignPermissions && systemInDesignPhase) {
      return redirect(createSystemDesignRoute(system.id));
    }

    const systemReservoirs = system.getReservoirNames();

    const waterLevels = await queryClient.ensureQueryData(
      waterLevelsMetricsQuery(systemReservoirs),
    );

    return {
      systems: listSystemsResponse,
      issues: listIssuesResponse,
      waterLevels,
    };
  };
